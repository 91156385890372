import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { withFirebase } from '../../app/components/Firebase';
import * as ROUTES from '../../constants/routes';
import useForm, { DEFAULT_FIELD } from '../../hooks/useForm';
import Email, { validator as emailValidator } from '../Form/inputs/Email';
import Password, { validator as passwordValidator } from '../Form/inputs/Password';
// import Button from '../Button';
import ButtonWithLoading
  from '../Form/inputs/ButtonWithLoading';

import { Typography } from '@material-ui/core';

const translations = {
  ui: {
    buttons: {
      emailSignIn: {
        label: 'Sign In',
        newLink: 'An error ocurred. Request a new email link',
      },
    },
    errors: {
      ERROR_EMAIL_SIGNIN_AUTH_PASSWORD: 'The combination of email and password does not match any account',
    },
  },
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
  },
  formFooter: {
    margin: theme.spacing(2),
  },
  formButtons: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  fields: {
    margin: theme.spacing(1),
    width: 300,
  },
  error: {
    backgroundColor: theme.palette.error.light,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderRadius: 3,
  },
}));

// const ERROR_CODE_ACCOUNT_EXISTS =
//   'auth/account-exists-with-different-credential';

// const ERROR_MSG_ACCOUNT_EXISTS = `
//   An account with an E-Mail address to
//   this social account already exists. Try to login from
//   this account instead and associate your social accounts on
//   your personal account page.
// `;

const ERROR_EMAIL_SIGNIN_AUTH_PASSWORD = `auth/wrong-password`;

const validators = {
  email: emailValidator,
  password: passwordValidator,
};
// const submitEmailLinkSignIn = ({ event, inputs, props, setInputs }) => {
//   const email = inputs.fields.email.value;
//   const { firebase } = props;

//   return firebase.doSignInWithEmailLink(email, window.location.href)
//     .then((authCredential) => {
//

//       navigate(ROUTES.APP_COURSES);
//       return ({
//         result: {},
//       });
//     })
//     .catch(error => {
//       console.log('error', error);
//       if (error.code === 'auth/invalid-action-code')
//         return ({
//           errors: error,
//           result: {},
//         });
//     });

// };

const submitEmailSignIn = ({ event, inputs, props, setInputs }) => {
  const { email, password } = inputs.fields;
  const { firebase } = props;
  event.persist();

  return firebase
    .doSignInWithToken(email.value, password.value)
    .then((authCredential) => {

      setInputs(nextInputs => ({
        ...nextInputs,
        fields: {
          ...nextInputs.fields,
          email: DEFAULT_FIELD,
          password: DEFAULT_FIELD,
        },
      }));
      navigate(ROUTES.APP_COURSES);
      return ({
        result: {},
      });
    })
    .catch(error => {
      console.log('error', error);
      return ({
        errors: error,
        result: {},
      });
    });

};

const SignInError = ({ errors, className }) => {
  const classes = useStyles();
  if (!errors) return null;
  if (errors[0] && errors[0].code === ERROR_EMAIL_SIGNIN_AUTH_PASSWORD) {
    return (
      <Box className={classes.error}>
        <Typography><strong>Error: </strong>{translations.ui.errors.ERROR_EMAIL_SIGNIN_AUTH_PASSWORD}
        </Typography>
      </Box>
    );
  } else if (errors[0]) {
    return (
      <Box className={classes.error}>
        <Typography><strong>Unexpected
          error: </strong>{errors[0].code}</Typography>
      </Box>
    );
  }
  return null;
};

const SignInFormBase = props => {
  const classes = useStyles();
  const {
    inputs,
    handleInputChange,
    handleSubmit,
    handleFocus,
    handleBlur,
    loading,
    success,
  } = useForm(props, submitEmailSignIn, {
    email: DEFAULT_FIELD,
    password: DEFAULT_FIELD,
  }, validators);

  return (
    <form className={classes.container}>
      <Email
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        inputs={inputs}
        className={classes.fields}
      />
      <Password
        handleInputChange={handleInputChange}
        handleBlur={handleBlur}
        handleFocus={handleFocus}
        inputs={inputs}
        className={classes.fields}
      />
      <Box className={classes.formButtons}>
        <ButtonWithLoading
          onClick={event => handleSubmit(event, inputs)}
          loading={loading} success={success}
          label={translations.ui.buttons.emailSignIn.label}/>
      </Box>
      <SignInError errors={inputs.errors}/>
    </form>
  );
};

// const SignInEmailLinkBase = props => {
//   const classes = useStyles();
//   const [link, setLink] = useState();
//   const {
//     inputs,
//     handleInputChange,
//     handleSubmit,
//     handleFocus,
//     handleBlur,
//     loading,
//     success,
//   } = useForm(props, submitEmailLinkSignIn, {
//     email: DEFAULT_FIELD,
//   }, validators);

//   const requestNewEmail = async () => {
//     const email = inputs.fields.email.value;
//     props.firebase.doSendSignInLinkToEmail(email);
//   };

//   return (
//     <form className={classes.container}>
//       <Email
//         handleInputChange={handleInputChange}
//         handleBlur={handleBlur}
//         handleFocus={handleFocus}
//         inputs={inputs}
//         className={classes.fields}
//       />
//       {!inputs.errors && !link &&
//       <Box className={classes.formButtons}>
//         <ButtonWithLoading
//           onClick={event => handleSubmit(event, inputs)}
//           loading={loading} success={success}
//           label={translations.ui.buttons.emailSignIn.label}/>
//       </Box>
//       }
//       {inputs.errors && <Button
//         onClick={requestNewEmail}>{translations.ui.buttons.emailSignIn.newLink}</Button>
//       }
//     </form>
//   );
// };

const SignInForm = withFirebase(SignInFormBase);

export default SignInForm;
