import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import validate from 'validate.js';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const FIELD = 'password';

const translations = {
  placeholder: 'Password',
  validationError: 'must contain at least 6 letters and a number, a capital letter, a lower case letter',
  togglePasswordVisibility: 'Toggle password visibility',
}

const passwordConstraints = 
{ 
  password: {
    presence: true,
    format: {
      flags: 'g',
      pattern: /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z].*[a-z].*[a-z].*[a-z]).{6,}$/,
      message: translations.validationError,
    }
  },
};

export const validator = (value, inputs) => {
  const result = validate({ password: value }, passwordConstraints);
  const errorMessage = (result && result.password) ? result.password.join(' ') : null;
  return { valid: (result === undefined), error: errorMessage }
}

const passwordConfirmationConstraints = secondaryField => ({
  [secondaryField]: {
    equality: 'password',
  }
});

export const confirmationValidator = secondaryField => (value, inputs) => {
  const result = validate(
    {
      password: inputs.fields.password.value,
      [secondaryField]: value,
    },
    passwordConfirmationConstraints(secondaryField)
  );

  const errorMessage = (result && result[secondaryField]) ? result[secondaryField].join(' ') : null;
  return { valid: (result === undefined), error: errorMessage }
}

const Password = ({ handleInputChange, handleFocus, handleBlur, inputs, useField = FIELD, placeholder = translations.placeholder, ...props }) => {
  const [state, setState] = useState({ showPassword : false });
  const handleClickShowPassword = () => setState({ showPassword: !state.showPassword} );
  const iconColor = (inputs.fields[useField].error) ? 'error' : 'disabled';

  return (
    <TextField
      id={useField}
      type={state.showPassword ? 'text' : 'password'}
      placeholder={placeholder}
      onChange={handleInputChange(useField)}
      onBlur={handleBlur(useField)}
      onFocus={handleFocus(useField)}
      value={inputs.fields[useField].value}
      error={!!inputs.fields[FIELD].error}
      helperText={inputs.fields[useField].error}
      required={inputs.fields[useField].required}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton tabIndex="-1" aria-label={translations.togglePasswordVisibility} onClick={handleClickShowPassword} >
              {state.showPassword ? <Visibility color={iconColor} /> : <VisibilityOff color={iconColor}/>}
            </IconButton>
          </InputAdornment> ),
      }}
      {...props}

    />
  )
};

export default Password;
