import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';

import Layout from '../../app/components/LayoutWithAuthentication';
import SignInForm from './SignInForm';


import Head from '../Form/layout/Head';
import Body from '../Form/layout/Body';
import Footer from '../Form/layout/Footer';
import { Helmet } from 'react-helmet';

const translations = {
  pageTitle: 'Melio - Sign In',
  page: {
    title: 'Sign In',
  },
}

const SignInPage = () => {
  return (
    <Fragment>
      <Head>
        <Typography variant="h3" gutterBottom align="center">{translations.page.title}</Typography>
      </Head>
      <Body>
        <SignInForm />
      </Body>
      <Footer />
    </Fragment>
  )
};

export default () => (
  <Layout>
    <Helmet>
      <title>{translations.pageTitle}</title>
    </Helmet>
    <SignInPage />
  </Layout>
);
